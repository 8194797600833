var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('icon-arrow-long', _vm._b({
    staticClass: "icon--direction--large"
  }, 'icon-arrow-long', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }
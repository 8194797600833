import axios from "@/plugins/axios";

import auth from "./auth";
import console from "./console";
import editor from "./editor";
// export { kakao } from "./kakao";
// export { naver } from "./naver";
import v1 from "./v1";

export default {
    auth,
    console,
    editor,
    v1,

    resource: {
        get(filename, isShort = false) {
            return axios({
                url: filename,
                method: "GET",
                responseType: "blob",
            }).then((result) => {
                try {
                    return new File([result.data], isShort ? filename?.split("/")?.pop() : filename);
                } catch (error) {
                    var blob = new Blob([result.data]);
                    blob.name = filename;
                    return blob;
                }
            });
        },
        
        post(file, filePath = "/res"){

            let formData = (() => {
                let f = new FormData();
                f.append("file", file);
                return f;
            })();

            return axios.post("/res", formData, { 
                headers: { 
                    "Content-Type": "multipart/form-data",
                    "file-path": filePath
                }
            }).then(res => res.data);
        },
    },
    
    getResource(filename, isShort = false) {
        return axios({
            url: filename,
            method: "GET",
            responseType: "blob",
        }).then((result) => {
            try {
                return new File([result.data], isShort ? filename?.split("/")?.pop() : filename);
            } catch (error) {
                var blob = new Blob([result.data]);
                blob.name = filename;
                return blob;
            }
        });
    },
};

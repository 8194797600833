<template>
    <section class="main-section">
        <v-container>
            <v-row v-if="title" no-gutters align="center" class="main-section__head">
                <v-col>
                    <tit-wrap-primary class="pb-0">
                        {{title}}
                        <template v-if="txt" slot="TxtAdd">
                            {{txt}}
                        </template>
                    </tit-wrap-primary>
                </v-col>
                <v-col data-aos="fade" data-aos-delay="200" v-if="this.$slots['mainSectionHead']" cols="12" lg="auto" class="mt-20px mt-lg-0">
                    <slot name="mainSectionHead" />
                </v-col>
            </v-row>
            <slot v-if="this.$slots['default']" />
        </v-container>
        <slot v-if="this.$slots['mainSectionImmersive']" name="mainSectionImmersive" />
    </section>
</template>

<script>
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        title: { type: String },
        txt: { type: String },
    },
    components: {
        TitWrapPrimary,
    },
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    position: relative;
    padding: var(--page-section-padding-y) 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        position: relative;
    }
    &--sm {
        padding: var(--page-section-padding-y-sm) 0;
    }
    &--first {
        padding-top: var(--contents-padding-top);
        margin-top: calc(var(--contents-padding-top) * -1);
    }
    &--last {
        padding-bottom: var(--contents-padding-bottom);
        margin-bottom: calc(var(--contents-padding-bottom) * -1);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    &__head{
        padding-bottom: var(--tit-wrap-padding-bottom-lg);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>

<template>
    <v-row align="center" no-gutters>
        <v-col cols="12" md="">
            <div class="pr-md-30px pb-40px pb-md-0">
                <tit-wrap-primary class="pb-0 white--text">
                    Contact Us
                    <template #TxtAdd>
                        <span class="white--text">
                            고객이 꿈꾸는 최적의 오피스 공간을 제안하고, 비츠만의 토탈 디자인 솔루션을 통해 <br class="d-none d-lg-block">
                            오피스 환경 개선 및 고객 맞춤형 공간을 구현해드립니다.
                        </span>
                    </template>
                </tit-wrap-primary>
            </div>
        </v-col>
        <v-col cols="12" md="auto">
            <v-sheet color="transparent" max-width="100%" class="inquiry-cards">
                <v-row no-gutters>
                    <v-col v-for="item in inquiry" :key="item.title" cols="6">
                        <v-responsive width="100%" class="h-100" :aspect-ratio="1 / 1">
                            <v-card :data-aos="item.aos" :data-aos-delay="item.aosDelay" :to="item.link" tile outlined class="inquiry-card h-100">
                                <div class="pa-20px pa-md-30px pa-lg-40px h-100 d-flex flex-column justify-space-between">
                                    <div class="mb-30px">
                                        <tit class="tit--sm break-keep white--text mb-6px mb-md-12px">
                                            {{item.title}}
                                        </tit>
                                        <txt class="txt--xs white--text line-height-15">
                                            <span v-html="item.info"></span>
                                        </txt>
                                    </div>                                    
                                    <btn-arrow-primary color="white" direction="right" class="v-btn--none-active-bg"/>
                                </div>
                            </v-card>
                        </v-responsive>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        TitWrapPrimary,
        BtnArrowPrimary,
        Tit,
        Txt,
    },
    data() {
        return {
            inquiry : [
                {
                    title : "쇼룸·대리점 안내",
                    info : "비츠의 제품을 가장 가까이 만날 수 있는 <br class='d-none d-xl-block' />쇼룸·대리점을 소개합니다.",
                    link : "/partners/list",
                    aos : "fade-right",
                },
                {
                    title : "대리점 개설 문의",
                    info : "비츠와 함께할 파트너를 모집합니다.",
                    link : "/partners/inquiry",
                    aos : "fade-down",
                    aosDelay: 50,
                },
                {
                    title : "브랜드 스토리",
                    info : "고객을 먼저 생각하는 공간, <br class='d-none d-xl-block' />가치있는 오피스 가구의 시작 비츠",
                    link : "/about",
                    aos : "fade-up",
                    aosDelay: 150,
                },
                {
                    title : "온라인 견적 문의",
                    info : "비츠의 전문 컨설턴트를 통해 지금 바로 <br class='d-none d-xl-block' />견적을 받아보세요.",
                    link : "/service/inquiry",
                    aos : "fade-left",
                    aosDelay: 100,
                },
            ],
        };
    },
}
</script>

<style lang="scss" scoped>
.inquiry-card{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: 0.15s ease-out;
    .v-btn{
        transition: 0.15s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){    
    .inquiry-cards{
        width: 400px;
    }
}
@media (min-width:1024px){    
    .inquiry-cards{
        width: 500px;
    }
    .inquiry-card{
        &:hover{
            border-color: #fff;
            .v-btn.v-btn--outlined.white--text{
                background-color: var(--v-primary-base) !important;
                border-color: var(--v-primary-base) !important;
            }
        }
    }
}
@media (min-width:1200px){    
    .inquiry-cards{
        width: 652px;
    }
}

</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header header--fixed header--menu-all"
  }, [_c('div', {
    staticClass: "header__body"
  }, [_c('v-container', [_c('h1', {
    staticClass: "header__logo"
  }, [_c('a', {
    attrs: {
      "href": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("비츠")])])]), _c('div', {
    staticClass: "header__contents"
  }, [_c('div', {
    staticClass: "header__contents-wrap"
  }, [_c('div', {
    staticClass: "header__gnb d-block d-xl-flex"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1)])]), _c('header-right')], 1)], 1), _c('btn-hamburger-primary', {
    staticClass: "btn-hamburger--mobile"
  }), _c('div', {
    staticClass: "header__bg"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
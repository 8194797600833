<template>
    <footer class="footer">
        <v-container>
            <v-row align="center">
                <v-col cols="12" md="">
                    <p class="font-primary mb-12px mb-md-16px">VIITZ</p>
                    <v-row class="ma-n2px mx-md-n8px my-md-n4px" style="max-width: 800px;">
                        <v-col v-for="item in info" :key="item.title" cols="auto" class="pa-2px px-md-8px py-md-4px">
                            <p class="page-text">
                                <span class="grey--text text--lighten-3">{{item.title}} </span><span class="grey--text text--lighten-1">{{item.info}}</span>
                            </p>
                        </v-col>
                    </v-row>
                    <p class="mt-12px mt-md-16px grey--text text--lighten-1">COPYRIGHT ⓒ EFFEX. ALL RIGHTS RESERVED.</p>
                </v-col>
                <v-col cols="auto" class="d-none d-md-block">
                    <v-img src="/images/ci-dark.svg" contain max-width="112" />
                </v-col>
            </v-row>
        </v-container>
    </footer>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            info : [
                { title : "대표이사", info : "장윤섭", },
                { title : "사업자등록번호", info : "134-81-03236", },
                { title : "문의번호", info : "1577-6536", },
                { title : "팩스", info : "02-303-6428", },
                { title : "이메일", info : "viitz@effex.co.kr", },
                { title : "주소", info : "서울특별시 강남구 테헤란로 126 대공빌딩 7층 ㈜이펙스", },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.footer{
    padding: 30px 0;
    background-color: var(--v-grey-darken4);
    font-family: var(--font-secondary);
    color: #fff;
    font-size: 1.4rem;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .footer{
        padding: 48px 0;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>

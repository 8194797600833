<template>
    <client-page class="app--main">

        <main-visual />

        <main-section title="Product" txt="비츠의 프리미엄 제품 Line-up 을 소개합니다." class="pb-0">
            <template slot="mainSectionHead">
                <v-row align="center" class="ma-n4px ma-md-n16px">
                    <v-col cols="auto" class="pa-4px pa-md-16px">
                        <txt class="txt--sm txt--light">※ 비츠의 더 많은 제품을 보시려면 클릭해주세요.</txt>
                    </v-col>
                    <v-col cols="auto" class="pa-4px pa-md-16px">
                        <btn-primary to="/product">More View</btn-primary>
                    </v-col>
                </v-row>
            </template>
            <template slot="mainSectionImmersive">
                <main-product></main-product>
            </template>
        </main-section>

        <main-section title="Portfolio" class="pb-0">
            <template slot="mainSectionHead">
                <btn-primary to="/reference">More View</btn-primary>
            </template>
            <template slot="mainSectionImmersive">
                <main-portfolio></main-portfolio>
            </template>
        </main-section>

        <main-section style="background-image: url(/images/main/inquiry-bg.jpg)">
            <main-inquiry></main-inquiry>
        </main-section>

        <popup-layer></popup-layer>
        
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainSection from "@/sets/styles/mains/main-section.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import MainProduct from "@/components/client/main/main-product.vue";
import MainPortfolio from "@/components/client/main/main-portfolio.vue";
import MainInquiry from "@/components/client/main/main-inquiry.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,
        MainVisual,
        MainSection,
        BtnPrimary,
        MainProduct,
        MainPortfolio,
        MainInquiry,

        Tit,
        Txt,
    },
    props: {},
    data() {
        return {};
    },
    mounted() {
        this.handleWheel = (event) => {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            var windowHeight = window.innerHeight || document.documentElement.clientHeight;

            if (scrollTop < windowHeight && event.deltaY > 0){
                // 비주얼 영역에서 휠 위로 굴렸을 때
                window.removeEventListener('wheel', this.handleWheel); // 기본 휠 이벤트 제어
                this.smoothScrollTo(windowHeight, 700);
            } else if (scrollTop < windowHeight && event.deltaY < 0){
                // 비주얼 영역에서 휠 아래로 굴렸을 때
                window.removeEventListener('wheel', this.handleWheel); // 기본 휠 이벤트 제어
                this.smoothScrollTo(0, 700);
            }
        };

        window.addEventListener('wheel', this.handleWheel, { passive: false });
    },
    destroyed() {
        window.removeEventListener('wheel', this.handleWheel);
    },
    methods: {
        smoothScrollTo(to, duration) {
            const start = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const change = to - start;
            const increment = 20;
            let currentTime = 0;

            const animateScroll = () => {
                currentTime += increment;
                const val = this.easeInOutQuad(currentTime, start, change, duration);
                window.scrollTo(0, val);
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    // 애니메이션 완료 후 휠 이벤트 리스너를 다시 추가합니다.
                    window.addEventListener('wheel', this.handleWheel, { passive: false });
                }
            };

            this.easeInOutQuad = function(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            };

            requestAnimationFrame(animateScroll);
        }
    }
};
</script>

<style lang="scss" scoped></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual white--text"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('div', {
    staticClass: "main-visual__inner"
  }, [_c('div', {
    staticClass: "main-visual__bg",
    staticStyle: {
      "background-image": "url(/images/main/v1.jpg)"
    }
  }), _c('div', {
    staticClass: "main-visual__contents main-visual__contents--main"
  }, [_c('v-container', [_c('v-responsive', {
    staticClass: "main-visual__title image-card w-100 mx-auto",
    staticStyle: {
      "background-image": "url(/images/main/visual-title.svg)"
    },
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-duration": "700",
      "data-aos-delay": "100",
      "aspect-ratio": 700 / 268
    }
  }), _c('v-card', {
    staticClass: "font-secondary w-100 mx-auto mt-40px mt-md-60px mt-lg-80px",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-duration": "700",
      "data-aos-delay": "200",
      "tile": "",
      "max-width": "620",
      "color": "rgba(0, 0, 0, 0.4)"
    }
  }, [_c('div', {
    staticClass: "pa-12px pa-md-16px"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "white--text line-height-1"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("V")]), _vm._v("iability")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": ""
    }
  }, [_c('txt', {
    staticClass: "white--text line-height-1"
  }, [_vm._v("실행 가능성")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-none d-sm-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "white--text line-height-1"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("I")]), _vm._v("ntegrity")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": ""
    }
  }, [_c('txt', {
    staticClass: "white--text line-height-1"
  }, [_vm._v("완전한 상태")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-none d-sm-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "white--text line-height-1"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("Z")]), _vm._v("one")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": ""
    }
  }, [_c('txt', {
    staticClass: "white--text line-height-1"
  }, [_vm._v("공간")])], 1)], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "btn-wrap d-flex justify-center"
  }, [_c('div', {
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-duration": "700",
      "data-aos-delay": "300"
    }
  }, [_c('btn-primary2', {
    attrs: {
      "to": "/about"
    }
  }, [_vm._v("VIITZ STORY")])], 1)])], 1)], 1)])]), _vm._l(_vm.slide, function (item) {
    return _c('swiper-slide', {
      key: item.title
    }, [_c('div', {
      staticClass: "main-visual__inner"
    }, [_c('div', {
      staticClass: "main-visual__bg",
      style: 'background-image: url(' + item.background + ')'
    }), _c('div', {
      staticClass: "main-visual__contents text-center text-md-left"
    }, [_c('v-container', [_c('tit', {
      staticClass: "tit--xl line-height-1 mb-16px mb-md-24px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('tit', {
      staticClass: "tit--xs white--text line-height-15 font-weight-medium"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.info)
      }
    })]), _c('div', {
      staticClass: "btn-wrap d-flex d-md-block justify-center"
    }, [_c('btn-primary2', {
      attrs: {
        "to": item.to
      }
    }, [_vm._v("More View")])], 1)], 1)], 1)])]);
  }), _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })]), _c('div', {
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "main-visual__btn main-visual__btn--prev",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }, [_vm._v("prev")]), _c('div', {
    staticClass: "main-visual__btn main-visual__btn--next",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }, [_vm._v("next")])])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="quick-menu">
        <div :class="['quick-menu__toggle', { active: isActive }]">
            <v-btn color="rgba(0, 0, 0, 0.6)" icon class="quick-menu__toggle-btn v-btn--none-active-bg v-btn--has-bg v-size--xx-large" @click="toggleActive">
                <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
            <div class="quick-menu__toggle-inner">
                <u-btn-flat-pill href="http://pf.kakao.com/_CVUXG" target="_blank" class="kakao-btn v-size--xx-large">
                    <v-img src="/images/icon/kakao.png" class="mr-6px" />
                    <span class="font-weight-bold">카카오톡 상담하기</span>
                </u-btn-flat-pill>
                <btn-quarternary to="/service/catalog" class="v-btn--none-active-bg">E – 카탈로그</btn-quarternary>
                <btn-quarternary to="/partners/list" class="v-btn--none-active-bg">쇼룸·대리점 안내</btn-quarternary>
                <btn-quarternary to="/product" class="v-btn--none-active-bg">제품 소개</btn-quarternary>
                <btn-quarternary to="/service/inquiry" class="v-btn--none-active-bg">고객 문의</btn-quarternary>
            </div>
        </div>
        <btn-top-primary class="mt-8px mt-md-12px" />
    </div>
</template>

<script>
import BtnTopPrimary from "@/components/publish/parents/buttons/btn-top-primary.vue";
import BtnQuarternary from "@/components/publish/parents/buttons/btn-quarternary.vue";
import UBtnFlatPill from "@/components/publish/styles/buttons/u-btn-flat-pill.vue";

export default {
    components: {
        BtnTopPrimary,
        BtnQuarternary,
        UBtnFlatPill,
    },
    data() {
        return {
            isActive: true,
        };
    },
    methods: {
        toggleActive() {
            this.isActive = !this.isActive;
        },
    },
};
</script>

<style lang="scss" scoped>
.quick-menu {
    position: fixed;
    z-index: 50;
    bottom: 12px;
    right: 12px;
    &__toggle {
        position: relative;
        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-100%);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            opacity: 0;
            visibility: hidden;
            transition: 0.15s ease-out;
            .v-btn {
                margin-bottom: 8px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(150%);
                transition: 0.15s ease-out;
            }
        }
        &-btn {
            background-color: rgba(0, 0, 0, 0.6) !important;
            transform: rotate(0);
            transition: 0.15s ease-out;
        }
        &.active {
            .quick-menu__toggle-btn {
                background-color: var(--v-primary-base) !important;
                transform: rotate(45deg);
            }
            .quick-menu__toggle-inner {
                opacity: 1;
                visibility: visible;
                .v-btn {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                    &:nth-child(1) {
                        transition: opacity 0.21s 0.4s ease-out, visibility 0.21s 0.4s ease-out, transform 0.21s 0.4s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(2) {
                        transition: opacity 0.21s 0.3s ease-out, visibility 0.21s 0.3s ease-out, transform 0.21s 0.3s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(3) {
                        transition: opacity 0.21s 0.2s ease-out, visibility 0.21s 0.2s ease-out, transform 0.21s 0.2s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(4) {
                        transition: opacity 0.21s 0.1s ease-out, visibility 0.21s 0.1s ease-out, transform 0.21s 0.1s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(5) {
                        transition: opacity 0.21s ease-out, visibility 0.21s ease-out, transform 0.21s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }

                    &.kakao-btn {
                        background-color: #fae100 !important;
                        color: var(--v-grey-darken4) !important;
                        .v-image {
                            max-width: 28px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .quick-menu {
        bottom: 40px;
        right: 40px;
        &__toggle {
            &-inner {
                .v-btn {
                    margin-bottom: 12px;
                    &.kakao-btn {
                        .v-image {
                            max-width: 34px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .quick-menu {
        &__toggle {
            &-inner {
                .v-btn:hover {
                    background-color: #fff !important;
                    color: var(--v-primary-base) !important;
                    box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
                }
            }
            &-btn:hover {
                background-color: var(--v-primary-base) !important;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>

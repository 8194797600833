var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "font-primary mb-12px mb-md-16px"
  }, [_vm._v("VIITZ")]), _c('v-row', {
    staticClass: "ma-n2px mx-md-n8px my-md-n4px",
    staticStyle: {
      "max-width": "800px"
    }
  }, _vm._l(_vm.info, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "pa-2px px-md-8px py-md-4px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "page-text"
    }, [_c('span', {
      staticClass: "grey--text text--lighten-3"
    }, [_vm._v(_vm._s(item.title) + " ")]), _c('span', {
      staticClass: "grey--text text--lighten-1"
    }, [_vm._v(_vm._s(item.info))])])]);
  }), 1), _c('p', {
    staticClass: "mt-12px mt-md-16px grey--text text--lighten-1"
  }, [_vm._v("COPYRIGHT ⓒ EFFEX. ALL RIGHTS RESERVED.")])], 1), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ci-dark.svg",
      "contain": "",
      "max-width": "112"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
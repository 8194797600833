var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section"
  }, [_c('v-container', [_vm.title ? _c('v-row', {
    staticClass: "main-section__head",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('tit-wrap-primary', {
    staticClass: "pb-0"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm.txt ? _c('template', {
    slot: "TxtAdd"
  }, [_vm._v(" " + _vm._s(_vm.txt) + " ")]) : _vm._e()], 2)], 1), this.$slots['mainSectionHead'] ? _c('v-col', {
    staticClass: "mt-20px mt-lg-0",
    attrs: {
      "data-aos": "fade",
      "data-aos-delay": "200",
      "cols": "12",
      "lg": "auto"
    }
  }, [_vm._t("mainSectionHead")], 2) : _vm._e()], 1) : _vm._e(), this.$slots['default'] ? _vm._t("default") : _vm._e()], 2), this.$slots['mainSectionImmersive'] ? _vm._t("mainSectionImmersive") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
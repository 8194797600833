<template>
    <v-sheet class="mb-n30px mb-md-0">
        <v-sheet v-for="item in product" :key="item.title" class="mb-30px mb-md-0">
            <v-row no-gutters :class="{ 'flex-md-row-reverse': item.reverse }">
                <v-col cols="12" md="6">
                    <div class="product__inner image-card" :style="'background-image: url('+item.background+')'">
                        <tit data-aos="fade-up" class="white--text">{{item.title}}</tit>
                        <txt data-aos="fade-up" data-aos-delay="200" class="font-secondary line-height-15 font-weight-medium white--text mt-10px mt-md-16px">
                            <span v-html="item.info"></span>
                        </txt>
                        <div data-aos="fade-up" data-aos-delay="300" class="btn-wrap">
                            <v-row class="row--sm">
                                <v-col cols="auto">
                                    <btn-secondary2 :to="item.linkMore" large>More View</btn-secondary2>
                                </v-col>
                                <v-col cols="auto">
                                    <btn-secondary :to="item.link" large>견적문의</btn-secondary>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6" >
                    <div class="product__inner product__inner--product d-flex aligne-center">
                        <v-row data-aos="fade" data-aos-delay="100" class="ma-xl-n30px">
                            <v-col v-for="child in item.children" :key="child.title" cols="6" class="pa-xl-30px">
                                <v-card :to="child.link" max-width="330" tile flat>
                                    <v-card tile outlined class="w-100">
                                        <v-img :src="child.image" :aspect-ratio="1 / 1" class="w-100"/>
                                    </v-card>
                                    <div class="mt-12px mt-md-24px">
                                        <txt class="txt--xs line-height-1 primary--text">{{item.title}}</txt>
                                        <txt class="text-truncate font-weight-medium mt-4px mt-md-8px txt--dark">{{child.title}}</txt>
                                        <txt class="txt--xs line-height-1 mt-10px mt-md-16px">￦ {{child.price}}</txt>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
    </v-sheet>
</template>

<script>
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        BtnSecondary,
        BtnSecondary2,
        Tit,
        Txt,
    },
    data() {
        return {
            product : [
                {
                    title : "XENSIA-X",
                    info : "XENSIA 는 사무환경을 보다 즐거운 공간으로 변화시켜 드립니다. <br class='d-none d-md-block' />뛰어난 디자인, 업무 특성에 따라 다양한 구성이 가능하여 분야별 효율을 높일 수 있는 <br class='d-none d-md-block' />사무 직군에 적합한 오피스 타입입니다.",
                    background : "/images/main/product-img1.jpg",
                    link : "/service/inquiry",
                    linkMore : "/product_view/64bf499a1fd72f3c9e34a1a7",
                    children : [
                        {
                            title : "직선형 책상 (D:800)",
                            price : "361,000~",
                            link : "/product_view/64bf499a1fd72f3c9e34a1a7",
                            image : "/images/main/product-img1-1.jpg",
                        },
                        {
                            title : "일반형 높이 조절 책상(2단)",
                            price : "828,000~",
                            link : "/product_view/64bf499a1fd72f3c9e34a1a7",
                            image : "/images/main/product-img1-2.jpg",
                        },
                    ]
                },
                {
                    title : "LEABEN",
                    info : "리더로서의 삶을 중시하는 LEABEN은 세련된 디자인을 통해 실용적이며 품격 있는  <br class='d-none d-md-blodk' />공간을 선사할 것입니다.",
                    background : "/images/main/product-img2.jpg",
                    link : "/service/inquiry",
                    linkMore : "/product_view/64c097d57a1a120bdab118bb",
                    reverse : true,
                    children : [
                        {
                            title : "직선형 책상",
                            price : "618,000~",
                            link : "/product_view/64c097d57a1a120bdab118bb",
                            image : "/images/main/product-img2-1.jpg",
                        },
                        {
                            title : "L형 책상",
                            price : "1,205,000~",
                            link : "/product_view/64c097d57a1a120bdab118bb",
                            image : "/images/main/product-img2-2.jpg",
                        },
                    ]
                },
                {
                    title : "N ACR",
                    info : "생각 전환을 위한 회의공간으로는 N ACR 시리즈를 제안 드립니다. <br class='d-none d-md-blodk' />심플하고 모던한 디자인으로 개인업무 공간과 다른 분위기를 통해 리프레쉬 효과를 주는 <br class='d-none d-md-blodk' />창의적인 회의공간을 구성할 수 있습니다.",
                    background : "/images/main/product-img3.jpg",
                    link : "/service/inquiry",
                    linkMore : "/product_view/64c1f85a7a1a120bdab12d5e",
                    children : [
                        {
                            title : "회의 테이블",
                            price : "573,000~",
                            link : "/product_view/64c1f85a7a1a120bdab12d5e",
                            image : "/images/main/product-img3-1.jpg",
                        },
                        {
                            title : "회의 테이블(전선덕트)",
                            price : "805,000~",
                            link : "/product_view/64c1f85a7a1a120bdab12d5e",
                            image : "/images/main/product-img3-2.jpg",
                        },
                    ]
                },
            ],
        };
    },
}
</script>

<style lang="scss" scoped>
.product{
    &__inner{
        width: 100%;
        height: 100%;
        padding: 30px 12px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .product{
        &__inner{
            padding: 50px 12px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .product{
        &__inner{
            padding: 100px 30px;
        }
    }
}
@media (min-width:1700px){
    .product{
        &__inner{
            padding: 160px;
            &--product{
                padding: 160px 120px;
            }
        }
    }
}

</style>
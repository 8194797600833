var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('btn-hamburger', {
    staticClass: "btn-hamburger--light",
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
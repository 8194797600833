import Vue from "vue";
import Vuetify from "vuetify";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#FAE4E8",
                lighten4: "#F3BDC5",
                lighten3: "#EB919E",
                lighten2: "#E36477",
                lighten1: "#DD435A",
                base: "#D7223D",
                darken1: "#D31E37",
                darken2: "#CD192F",
                darken3: "#C71427",
                darken4: "#BE0C1A",
            },
            secondary: {
                lighten5: "#EDEAE7",
                lighten4: "#D1CBC4",
                lighten3: "#B2A89D",
                lighten2: "#938576",
                lighten1: "#7C6A58",
                base: "#65503B",
                darken1: "#5D4935",
                darken2: "#53402D",
                darken3: "#493726",
                darken4: "#372719",
            },
            accent: {
                base: "#F96F00",
                // lighten5: "#E5ECFF",
                // lighten4: "#BFD0FF",
                // lighten3: "#94B1FF",
                // lighten2: "#6991FF",
                // lighten1: "#497AFF",
                // base: "#2962FF",
                // darken1: "#245AFF",
                // darken2: "#1F50FF",
                // darken3: "#1946FF",
                // darken4: "#0F34FF",
            },
            grey: {
                lighten5: "#F7F7F7",
                lighten4: "#ECECEC",
                lighten3: "#DDD",
                lighten2: "#AAA",
                lighten1: "#999",
                base: "#666",
                darken1: "#555",
                darken2: "#444",
                darken3: "#222",
                darken4: "#111",
            },
            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
            info: "#2196F3",
            anchor: "inherit",
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            info: "#2D3539",
            content: "#EDF0F5",
            anchor: "#333",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}

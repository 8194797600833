<template>
    <u-tit-wrap-default v-bind="$attrs">
        <!-- <u-tit-subtit v-if="this.$slots['TitAdd']" class="tit--xs"><slot name="TitAdd" /></u-tit-subtit> -->
        <u-tit-default data-aos="fade-up" class="tit--xl line-height-1 break-keep"><slot /></u-tit-default>
        <u-txt-default data-aos="fade-up" data-aos-delay="100" v-if="this.$slots['TxtAdd']" class="font-secondary txt--xl txt--dark font-weight-medium line-height-15"><slot name="TxtAdd" /></u-txt-default>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
// import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UTitWrapDefault,
        UTitDefault,
        // UTitSubtit,
        UTxtDefault,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>

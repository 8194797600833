<template>
    <v-btn v-bind="{ color, ...$attrs }" color="white" icon class="btn-top-primary font-primary v-size--xx-large" @click="$vuetify.goTo(target, options)">
        <div class="line-height-0">
            <div class="font-size-14 font-weight-semibold grey--text text--darken-4 line-height-1">TOP</div>
        </div>
    </v-btn>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    props: {
        color: { type: String, default: "grey" },
    },
    components: {
        IconArrowPrimary,
    },
    data: () => {
        return {
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    mounted() {},
    methods: {
        // onScroll: function () {
        //     var scrollTop = window.scrollY || document.documentElement.scrollTop;
        //     var scrollTopBtn = document.querySelector(".btn-top-primary");
        //     if (scrollTopBtn !== null) {
        //         if (scrollTop > window.innerHeight / 2) {
        //             scrollTopBtn.classList.add("active");
        //         } else {
        //             scrollTopBtn.classList.remove("active");
        //         }

        //         var innerHeight = window.innerHeight;
        //         var scrollHeight = document.body.scrollHeight;
        //         if (scrollTop + innerHeight >= scrollHeight) {
        //             scrollTopBtn.classList.add("bottom");
        //         } else {
        //             scrollTopBtn.classList.remove("bottom");
        //         }
        //     }
        // },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-top-primary
.btn-top-primary {
    transition: all ease-out 0.2s;
    box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
    will-change: unset !important;
    &.v-btn:not(.v-btn--has-bg) {
        background-color: #fff !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>

<template>
    <div v-if="$vuetify.breakpoint.xlOnly" class="header__right d-none d-xl-flex">
        <v-row align="center" class="ma-n8px">
            <v-col cols="auto" class="pa-8px">
                <router-link to="/service/inquiry" class="header__right-link">온라인 견적문의</router-link>
            </v-col>
            <v-col cols="auto" class="pa-8px auto">
                <span class="line"></span>
            </v-col>
            <v-col cols="auto" class="pa-8px">
                <router-link to="/partners/list" class="header__right-link">쇼룸 대리점 안내</router-link>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.header__right{
    &-link{
        display: block;
        font-size: 1.6rem;
        color: #fff;
        transition: all ease-out .15s;
        &:hover{
            color: var(--v-primary-base) !important;
        }
    }
}
.header:hover,
.header.scroll-active{
    .header__right{
        &-link{
            color: var(--v-grey-base);
        }
    }
}
.line{
    display: block;
    width: 1px;
    height: 12px;
    background: var(--v-grey-lighten3);
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "quick-menu"
  }, [_c('div', {
    class: ['quick-menu__toggle', {
      active: _vm.isActive
    }]
  }, [_c('v-btn', {
    staticClass: "quick-menu__toggle-btn v-btn--none-active-bg v-btn--has-bg v-size--xx-large",
    attrs: {
      "color": "rgba(0, 0, 0, 0.6)",
      "icon": ""
    },
    on: {
      "click": _vm.toggleActive
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-plus")])], 1), _c('div', {
    staticClass: "quick-menu__toggle-inner"
  }, [_c('u-btn-flat-pill', {
    staticClass: "kakao-btn v-size--xx-large",
    attrs: {
      "href": "http://pf.kakao.com/_CVUXG",
      "target": "_blank"
    }
  }, [_c('v-img', {
    staticClass: "mr-6px",
    attrs: {
      "src": "/images/icon/kakao.png"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("카카오톡 상담하기")])], 1), _c('btn-quarternary', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "to": "/service/catalog"
    }
  }, [_vm._v("E – 카탈로그")]), _c('btn-quarternary', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "to": "/partners/list"
    }
  }, [_vm._v("쇼룸·대리점 안내")]), _c('btn-quarternary', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "to": "/product"
    }
  }, [_vm._v("제품 소개")]), _c('btn-quarternary', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "to": "/service/inquiry"
    }
  }, [_vm._v("고객 문의")])], 1)], 1), _c('btn-top-primary', {
    staticClass: "mt-8px mt-md-12px"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
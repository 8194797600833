<template>
    <div v-if="className == 'sub-tab'" :class="className">
        <v-container>
            <template v-for="item in gnbs">
                <v-tabs v-if="item.title == sh" :key="item.title" hide-slider>
                    <v-tab v-for="child in item.children" :key="child.title" :to="child.path" :class="{ 'v-tab--active': tabActive == child.title }">{{ child.title }}</v-tab>
                </v-tabs>
            </template>
        </v-container>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className+'__li'" :key="item.title">
                <router-link :to="item.path" :class="className+'__link'"><h2>{{item.title}}</h2></router-link>
            </li>

            <li v-else :class="className+'__li'" :key="item.title">
                <div :class="className+'__link gnb__link--toggle d-xl-none'"><h2>{{item.title}}</h2></div>
                <router-link :to="item.path" :class="className+'__link gnb__link--toggle d-none d-xl-flex'"><h2>{{item.title}}</h2></router-link>
                <ul :class="className+'__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{child.title}}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
    <ul v-else-if="className == 'slide-gnb'" :class="className">
        <template v-for="item in gnbs">
            <li :class="className+'__li'" :key="item.title">
                <router-link :to="item.path" :class="className+'__link'"><h2>{{item.title}}</h2></router-link>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className : String,
        indexNum : String,
        sh: String,
        tabActive: String,
    },
    data: function(){
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs : [
                {
                    title : "Product",
                    path : "/product",
                },
                {
                    title : "Reference",
                    path : "/reference",
                    children : [
                        {
                            title : "Office",
                            path : "/reference",
                        },
                        {
                            title : "Public·Education",
                            path : "/reference",
                        },
                        {
                            title : "Medical",
                            path : "/reference",
                        },
                        {
                            title : "Dormitory",
                            path : "/reference",
                        },
                    ]
                },
                {
                    title : "Partners",
                    path : "/partners/list",
                    children : [
                        {
                            title : "쇼룸·대리점 안내",
                            path : "/partners/list",
                        },
                        {
                            title : "대리점 개설 문의",
                            path : "/partners/inquiry",
                        },
                    ]
                },
                {
                    title : "Customer Service",
                    path : "/service/inquiry",
                    children : [
                        {
                            title : "온라인(견적/구매) 문의",
                            path : "/service/inquiry",
                        },
                        {
                            title : "A/S",
                            path : "/service/as",
                        },
                        {
                            title : "E-카다로그",
                            path : "/service/catalog",
                        },
                        {
                            title : "공지사항",
                            path : "/service/notice",
                        },
                    ]
                },
                {
                    title : "About",
                    path : "/about",
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted: function(){
    },
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    }
};
</script>
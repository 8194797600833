<template>
    <v-btn v-bind="{ color, ...$attrs }" outlined tile class="v-btn-outlined-tile-arrow v-btn--none-active-bg v-btn--width-fixed" @click="$emit('click')">
        <span v-for="(item, slotName, index) in $slots" :key="index" class="v-btn__content-inner">
            <slot :name="slotName" />
            <icon-arrow-primary direction="right" />
        </span>
    </v-btn>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
export default {
    props: {
        color: { type: String, default: "grey" },
    },
    components: {
        IconArrowPrimary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-btn-outlined-tile-arrow{
    box-sizing: border-box;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    transition: 0.15s ease-out;
}
.v-btn-outlined-tile-arrow:not(:hover){
    padding: 0;
}
.v-btn__content-inner{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-application .v-btn.v-btn:not(.v-btn--has-bg).v-btn-outlined-tile-arrow:hover{
        background-color: var(--v-primary-base) !important;
        border-color: var(--v-primary-base);
        color: #fff !important;
    }
}
@media (min-width:1200px){
}

</style>

<template>
    <div class="portfolio-slide white--text">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide v-for="item in portfolio" :key="item.title">
                <router-link :to="item.link" class="portfolio-slide__inner" :style="'background-image: url('+item.background+')'">
                    <div class="portfolio-slide__contents">
                        <v-container class="h-100">
                            <div class="py-60px w-100 h-100 d-flex flex-column justify-space-between">
                                <div class="mb-20px portfolio-slide__head">
                                    <txt class="white--text line-height-15 mb-6px mb-md-12px">
                                        {{item.subTitle}}
                                    </txt>
                                    <tit class="break-keep font-secondary white--text">{{item.title}}</tit>
                                </div>
                                <v-card tile flat color="rgba(0, 0, 0, .4)" class="portfolio-slide__info-box">
                                    <div class="pa-16px pa-md-24px px-lg-60px">
                                        <v-row class="ma-xl-n20px">
                                            <v-col v-for="child in item.children" :key="child.title" cols="auto" class="pa-xl-20px">
                                                <v-row align="center" class="row--xs">
                                                    <v-col cols="auto">
                                                        <txt class="txt--xs white--text">{{child.title}}</txt>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <span class="line"></span>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <txt class="txt--xs grey--text text--lighten-3">{{child.info}}</txt>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </div>
                        </v-container>
                    </div>
                </router-link>
            </swiper-slide>
            <div class="swiper-control">
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
            <div slot="pagination" @click.prevent>
                <div class="portfolio-slide__btn portfolio-slide__btn--prev" slot="button-prev">prev</div>
                <div class="portfolio-slide__btn portfolio-slide__btn--next" slot="button-next">next</div>
            </div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        Tit,
        Txt,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                effect: "fade",
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                pagination: {
                    el: '.portfolio-slide .swiper-pagination',
                    clickable: true,
                    renderBullet: function(index, className) {
                        const number = (index + 1 < 10) ? '0' + (index + 1) : (index + 1);
                        return '<span class="' + className + '">' + number + '</span>';
                    },
                },
                navigation: {
                    nextEl: ".portfolio-slide .portfolio-slide__btn--next",
                    prevEl: ".portfolio-slide .portfolio-slide__btn--prev",
                },
            },
            portfolio : [
                {
                    title : "스탠포드 연구소 인천글로벌캠퍼스",
                    subTitle : "Stanford Center at the Incheon Global Campus",
                    background : "/images/main/portfolio-img.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "교육, 공공",
                        },
                        {
                            title : "구성공간",
                            info : "사무공간, 임원실, 회의실, 라운지, 인테리어",
                        },
                        {
                            title : "적용제품",
                            info : "M70, METIC",
                        },
                        {
                            title : "시공일자",
                            info : "2021.05",
                        },
                    ]
                },
                {
                    title : "원스토어",
                    subTitle : "Onestore",
                    background : "/images/main/portfolio-img2.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "통신, 서비스",
                        },
                        {
                            title : "구성공간",
                            info : "사무공간",
                        },
                        {
                            title : "적용제품",
                            info : "FLOT",
                        },
                        {
                            title : "시공일자",
                            info : "2019.01",
                        },
                    ]
                },
                {
                    title : "헬스하우스",
                    subTitle : "Health House",
                    background : "/images/main/portfolio-img3.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "무역, 전자상거래",
                        },
                        {
                            title : "구성공간",
                            info : "사무공간, 회의실, 임원실, 공용공간",
                        },
                        {
                            title : "적용제품",
                            info : "XENSIA, RAPLAS, LEABEN, NACR, LICE",
                        },
                        {
                            title : "시공일자",
                            info : "2022.01",
                        },
                    ]
                },
                {
                    title : "삼일회계법인",
                    subTitle : "Samil Pricewaterhouse Coopers",
                    background : "/images/main/portfolio-img4.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "회계",
                        },
                        {
                            title : "구성공간",
                            info : "사무공간",
                        },
                        {
                            title : "적용제품",
                            info : "XENSIA, COMMON, FCR",
                        },
                        {
                            title : "시공일자",
                            info : "2018.02",
                        },
                    ]
                },
                {
                    title : "MBC 문화방송",
                    subTitle : "MBC",
                    background : "/images/main/portfolio-img5.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "방송, 공공",
                        },
                        {
                            title : "구성공간",
                            info : "임원실, 보도국, 편집실, 회의실, 공용공간",
                        },
                        {
                            title : "적용제품",
                            info : "N VREX, XENSIA, PICASSO, LICE, COMMON, MYLO, FCF4000",
                        },
                        {
                            title : "시공일자",
                            info : "2015.07",
                        },
                    ]
                },
                {
                    title : "에스오일",
                    subTitle : "S-oil",
                    background : "/images/main/portfolio-img6.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "유통, 영업",
                        },
                        {
                            title : "구성공간",
                            info : "사무공간, 휴게공간, 회의실",
                        },
                        {
                            title : "적용제품",
                            info : "N VREX, TIR, FCR, MYLO, TERRA, 제작가구, 강연대",
                        },
                        {
                            title : "시공일자",
                            info : "2011~현재",
                        },
                    ]
                },
                {
                    title : "은평성모병원",
                    subTitle : "Eunpyeong ST. Mary’s Hospital",
                    background : "/images/main/portfolio-img7.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "의료, 대학병원",
                        },
                        {
                            title : "구성공간",
                            info : "진료실, 대기실, 치료실, 휴게공간, 병동, 수술실, 간호사실",
                        },
                        {
                            title : "적용제품",
                            info : "N IRIS, REFFICO, METIC, COMMON, 진료데스크, 진료베드, 린넨장, 상두대, 수납장, 모니터암, 소파, 제작가구",
                        },
                        {
                            title : "시공일자",
                            info : "2019.01",
                        },
                    ]
                },
                {
                    title : "LG디스플레이 기숙사",
                    subTitle : "LG Display Dormitory",
                    background : "/images/main/portfolio-img8.jpg",
                    link : "/reference",
                    children : [
                        {
                            title : "업종",
                            info : "기숙사",
                        },
                        {
                            title : "구성공간",
                            info : "기숙사",
                        },
                        {
                            title : "적용제품",
                            info : "책상, 의자, 침대, 캐비닛, 수납장",
                        },
                        {
                            title : "시공일자",
                            info : "2010.08",
                        },
                    ]
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// portfolio-slide
.portfolio-slide {
    width: 100%;
    &__inner {
        min-height: 480px;
        position: relative;
        display: flex;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__contents{
        width: 100%;
        height: 100%;
    }
    &__btn{
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 80px;
        background-color: rgba($color: #000, $alpha: .3);
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color:  rgba($color: #fff, $alpha: .7);
        transition: 0.1s ease-out;
        &--prev{
            left: 0;
            border-radius: 0 999px 999px 0;
        }
        &--next{
            right: 0;
            border-radius: 999px 0 0 999px;
        }
    }
    .swiper-control{
        bottom: unset;
        top: 30px;
        max-width: calc(var(--container-lg) + var(--container-gutter)*2);
        .swiper-pagination.swiper-pagination-bullets{
            position: relative;
            left: 0;
            bottom: 0;
            align-items: center;
            margin-right: -12px;
        }
    }
}
::v-deep{
    .swiper-pagination-bullet{
        width: 16px;
        height: auto;
        background-color: transparent !important;
        font-size: 1.2rem;
        font-weight: 700;
        text-align: left;
        color: rgba($color: #fff, $alpha: .4);
        border: 0;
        margin: 0 12px 0 0;
        padding-right: 0;
        transition: 0.25s ease-out;
        opacity: 1;
        &::after{
            width: 24px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            border-radius: 0;
            top: 50%;
            right: 0;
            transform: translateY(-50%) scaleX(0);
            transform-origin: right center;
            transition: 0.25s ease-out;
        }
        &-active{
            width: 64px;
            color: #fff;
            &::after{
                transform: translateY(-50%) scaleX(1);
            }
        }
    }
}
.line{
    display: block;
    width: 1px;
    height: 12px;
    background-color: rgba($color: #fff, $alpha: .3);
}
@media (min-width: 576px) {
    .portfolio-slide {
        &__inner {
            min-height: 400px;
        }
    }
}
@media (min-width: 768px) {
    .portfolio-slide {
        .swiper-control{
            top: 60px;
            .swiper-pagination.swiper-pagination-bullets{
                justify-content: flex-end;
                margin-right: -24px;
            }
        }
        &__inner {
            min-height: 0;
            height: 0;
            padding-top: calc((830 / 1920) * 100%);
        }
        &__contents{
            position: absolute;
            top: 0;
            left: 0;
        }
        &__head{
            padding-right: 400px;
        }
    }
    ::v-deep{
        .swiper-pagination-bullet{
            width: 20px;
            font-size: 1.4rem;
            margin: 0 24px 0 0;
            &::after{
                width: 40px;
            }
            &-active{
                width: 84px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .portfolio-slide{
        &__info-box{
            opacity: 0;
            visibility: hidden;
            transition: 0.2s ease-out;
        }
        &__inner:hover{
            .portfolio-slide__info-box{
                opacity: 1;
                visibility: visible;
            }
        }
        &__btn:hover{
            background-color: var(--v-primary-base);
            color: #fff;
        }
    }
}
@media (min-width: 1300px) {
    .portfolio-slide {        
        &__btn{
            display: flex;
        }
    }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "mb-n30px mb-md-0"
  }, _vm._l(_vm.product, function (item) {
    return _c('v-sheet', {
      key: item.title,
      staticClass: "mb-30px mb-md-0"
    }, [_c('v-row', {
      class: {
        'flex-md-row-reverse': item.reverse
      },
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "product__inner image-card",
      style: 'background-image: url(' + item.background + ')'
    }, [_c('tit', {
      staticClass: "white--text",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('txt', {
      staticClass: "font-secondary line-height-15 font-weight-medium white--text mt-10px mt-md-16px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.info)
      }
    })]), _c('div', {
      staticClass: "btn-wrap",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "300"
      }
    }, [_c('v-row', {
      staticClass: "row--sm"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('btn-secondary2', {
      attrs: {
        "to": item.linkMore,
        "large": ""
      }
    }, [_vm._v("More View")])], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('btn-secondary', {
      attrs: {
        "to": item.link,
        "large": ""
      }
    }, [_vm._v("견적문의")])], 1)], 1)], 1)], 1)]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "product__inner product__inner--product d-flex aligne-center"
    }, [_c('v-row', {
      staticClass: "ma-xl-n30px",
      attrs: {
        "data-aos": "fade",
        "data-aos-delay": "100"
      }
    }, _vm._l(item.children, function (child) {
      return _c('v-col', {
        key: child.title,
        staticClass: "pa-xl-30px",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-card', {
        attrs: {
          "to": child.link,
          "max-width": "330",
          "tile": "",
          "flat": ""
        }
      }, [_c('v-card', {
        staticClass: "w-100",
        attrs: {
          "tile": "",
          "outlined": ""
        }
      }, [_c('v-img', {
        staticClass: "w-100",
        attrs: {
          "src": child.image,
          "aspect-ratio": 1 / 1
        }
      })], 1), _c('div', {
        staticClass: "mt-12px mt-md-24px"
      }, [_c('txt', {
        staticClass: "txt--xs line-height-1 primary--text"
      }, [_vm._v(_vm._s(item.title))]), _c('txt', {
        staticClass: "text-truncate font-weight-medium mt-4px mt-md-8px txt--dark"
      }, [_vm._v(_vm._s(child.title))]), _c('txt', {
        staticClass: "txt--xs line-height-1 mt-10px mt-md-16px"
      }, [_vm._v("￦ " + _vm._s(child.price))])], 1)], 1)], 1);
    }), 1)], 1)])], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-section', {
    staticClass: "pb-0",
    attrs: {
      "title": "Product",
      "txt": "비츠의 프리미엄 제품 Line-up 을 소개합니다."
    }
  }, [_c('template', {
    slot: "mainSectionHead"
  }, [_c('v-row', {
    staticClass: "ma-n4px ma-md-n16px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-4px pa-md-16px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--sm txt--light"
  }, [_vm._v("※ 비츠의 더 많은 제품을 보시려면 클릭해주세요.")])], 1), _c('v-col', {
    staticClass: "pa-4px pa-md-16px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    attrs: {
      "to": "/product"
    }
  }, [_vm._v("More View")])], 1)], 1)], 1), _c('template', {
    slot: "mainSectionImmersive"
  }, [_c('main-product')], 1)], 2), _c('main-section', {
    staticClass: "pb-0",
    attrs: {
      "title": "Portfolio"
    }
  }, [_c('template', {
    slot: "mainSectionHead"
  }, [_c('btn-primary', {
    attrs: {
      "to": "/reference"
    }
  }, [_vm._v("More View")])], 1), _c('template', {
    slot: "mainSectionImmersive"
  }, [_c('main-portfolio')], 1)], 2), _c('main-section', {
    staticStyle: {
      "background-image": "url(/images/main/inquiry-bg.jpg)"
    }
  }, [_c('main-inquiry')], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
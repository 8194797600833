var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "btn-top-primary font-primary v-size--xx-large",
    attrs: {
      "color": "white",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, 'v-btn', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_c('div', {
    staticClass: "line-height-0"
  }, [_c('div', {
    staticClass: "font-size-14 font-weight-semibold grey--text text--darken-4 line-height-1"
  }, [_vm._v("TOP")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="main-visual white--text">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide>
                <div class="main-visual__inner">
                    <div class="main-visual__bg" style="background-image: url(/images/main/v1.jpg)"></div>
                    <div class="main-visual__contents main-visual__contents--main">
                        <v-container>
                            <v-responsive data-aos="zoom-out" data-aos-duration="700" data-aos-delay="100" :aspect-ratio="700 / 268" class="main-visual__title image-card w-100 mx-auto" style="background-image: url(/images/main/visual-title.svg)"></v-responsive>
                            <v-card data-aos="zoom-out" data-aos-duration="700" data-aos-delay="200" tile max-width="620" color="rgba(0, 0, 0, 0.4)" class="font-secondary w-100 mx-auto mt-40px mt-md-60px mt-lg-80px">
                                <div class="pa-12px pa-md-16px">
                                    <v-row align="center" justify="center" class="row--lg">
                                        <v-col cols="12" sm="auto">
                                            <v-row justify="center" class="row--xs">
                                                <v-col cols="auto">
                                                    <txt class="white--text line-height-1"><strong class="primary--text">V</strong>iability</txt>
                                                </v-col>
                                                <v-col cols="auto" sm="">
                                                    <txt class="white--text line-height-1">실행 가능성</txt>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="auto" class="d-none d-sm-block">
                                            <span class="line"></span>
                                        </v-col>
                                        <v-col cols="12" sm="auto">
                                            <v-row justify="center" class="row--sm">
                                                <v-col cols="auto">
                                                    <txt class="white--text line-height-1"><strong class="primary--text">I</strong>ntegrity</txt>
                                                </v-col>
                                                <v-col cols="auto" sm="">
                                                    <txt class="white--text line-height-1">완전한 상태</txt>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="auto" class="d-none d-sm-block">
                                            <span class="line"></span>
                                        </v-col>
                                        <v-col cols="12" sm="auto">
                                            <v-row justify="center" class="row--sm">
                                                <v-col cols="auto">
                                                    <txt class="white--text line-height-1"><strong class="primary--text">Z</strong>one</txt>
                                                </v-col>
                                                <v-col cols="auto" sm="">
                                                    <txt class="white--text line-height-1">공간</txt>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                            <div class="btn-wrap d-flex justify-center">
                                <div data-aos="zoom-out" data-aos-duration="700" data-aos-delay="300">
                                    <btn-primary2 to="/about">VIITZ STORY</btn-primary2>
                                </div>
                            </div>
                        </v-container>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide v-for="item in slide" :key="item.title">
                <div class="main-visual__inner">
                    <div class="main-visual__bg" :style="'background-image: url('+item.background+')'"></div>
                    <div class="main-visual__contents text-center text-md-left">              
                        <v-container>
                            <tit class="tit--xl line-height-1 mb-16px mb-md-24px">
                                {{item.title}}
                            </tit>
                            <tit class="tit--xs white--text line-height-15 font-weight-medium">
                                <span v-html="item.info"></span>
                            </tit>
                            <div class="btn-wrap d-flex d-md-block justify-center">
                                <btn-primary2 :to="item.to">More View</btn-primary2>
                            </div>
                        </v-container>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-control">
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
            <div slot="pagination" @click.prevent>
                <div class="main-visual__btn main-visual__btn--prev" slot="button-prev">prev</div>
                <div class="main-visual__btn main-visual__btn--next" slot="button-next">next</div>
            </div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import BtnPrimary2 from "@/components/publish/parents/buttons/btn-primary2.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        BtnPrimary2,
        Tit,
        Txt,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                effect: "fade",
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                pagination: {
                    el: '.main-visual .swiper-pagination',
                    clickable: true,
                    renderBullet: function(index, className) {
                        const number = (index + 1 < 10) ? '0' + (index + 1) : (index + 1);
                        return '<span class="' + className + '">' + number + '</span>';
                    },
                },
                navigation: {
                    nextEl: ".main-visual .main-visual__btn--next",
                    prevEl: ".main-visual .main-visual__btn--prev",
                },
            },
            slide : [
                { 
                    title : "XENSIA-X",
                    info : "현대 업무환경은 수직적 조직체계의 단순화된 개인 업무가 아닌 팀원 간의 유기적인 결합과 소통이 능률 향상에 핵심요소로 나타나고 있습니다. <br class='d-none d-lg-block' />XENSIA의 오픈 플랜 워크스테이션은 당신과 동료들의 열린 커뮤니케이션을 도와드립니다. <br class='d-none d-lg-block' />뿐만 아니라 다양한 개인 Storage Unit과 Acc 조합을 통해 사용자의 업무 유형에 맞는 다양한 XENSIA 모듈을 구성해 드립니다.",
                    to : "/product_view/64bf499a1fd72f3c9e34a1a7",
                    background : "/images/main/v2.jpg",
                },
                { 
                    title : "4IS-X",
                    info : "4IS-X 시리즈는 SOHO 트렌드가 반영된 제품으로 디자인, 기능, 구성, 경제적인 가격의 장점을 담은 제품입니다. <br class='d-none d-lg-block' />불필요함을 덜어낸 슬림한 디자인과 업무 집중도를 높여주는 컴팩트한 구성으로 경제적인 장점을 갖춘 4IS-X 시리즈는 <br class='d-none d-lg-block' />업무 패턴에 따른 제품 구성을 통해 다양한 모듈 구성과 회의 테이블 구성이 가능합니다.",
                    to : "/product_view/64bf58561fd72f3c9e34a4e9",
                    background : "/images/main/v3.jpg",
                },
                { 
                    title : "LAYMON",
                    info : "LAYMON CABINET 시리즈는 어떤 구조의 공간이든 가장 효율적인 공간을 구성할 수 있는 최적의 수납 시스템을 제공합니다. <br class='d-none d-lg-block' />사용자의 용도에 따라 오픈형 캐비닛에 다양한 사이즈의 도어를 결합하여 원하는 형태의 캐비닛 모듈구성이 가능합니다.",
                    to : "/product_view/64c07f897a1a120bdab1163b",
                    background : "/images/main/v4.jpg",
                },
                { 
                    title : "LEABEN",
                    info : "리더로서의 삶을 중시하는 LEABEN은 세련된 디자인을 통해 실용적이며 품격 있는 공간을 선사할 것입니다. <br class='d-none d-lg-block' />창조적이며 혁신을 추구하는 귀하와 함께하겠습니다.",
                    to : "/product_view/64c097d57a1a120bdab118bb",
                    background : "/images/main/v5.jpg",
                },
                { 
                    title : "RAPLAS",
                    info : "RAPLAS는 음고의 기준 7음계 중 ‘라’ 와 태양의 신을 의미하는 ‘라’ 그리고 ‘장소’ 와 ‘더하다’는 발음의 합성어로 <br class='d-none d-lg-block' />‘높은 자리의 기준이며 무한한 가치를 더해가는 기업의 중역용 가구’라는 의미를 담고 있습니다.",
                    to : "/product_view/64c082f77a1a120bdab11717",
                    background : "/images/main/v6.jpg",
                },
                { 
                    title : "N ACR",
                    info : "생각 전환을 위한 회의공간 N ACR 시리즈입니다. <br class='d-none d-lg-block' />심플하고 모던한 디자인으로 개인업무 공간과 다른 분위기를 통해 리프레쉬 효과를 주는 창의적인 회의공간을 구성할 수 있습니다.",
                    to : "/product_view/64c1f85a7a1a120bdab12d5e",
                    background : "/images/main/v7.jpg",
                },
                { 
                    title : "FCR",
                    info : "접이식 테이블인 FCR 시리즈는 다용도실, 대회의실, 세미나실 등 다양한 공간에서 활용할 수 있는 적층형 구조를 가지고 있습니다. <br class='d-none d-lg-block' />수납성과 활용성이 좋아서 좁은 회의공간에서도 공간 활용도를 높일 수 있습니다.",
                    to : "/product_view/64c1fb167a1a120bdab12e05",
                    background : "/images/main/v8.jpg",
                },
                { 
                    title : "CHAIR",
                    info : "많은 직장인들이 침대에서 보다 의자에서 더 많은 시간을 보냅니다. 일반적인 직장인의 평균 근무시간은 8시간 이상입니다. <br class='d-none d-lg-block' />따라서 사무실에서 근무하는 시간이 많으면 많을수록 내 몸에 꼭 맞는 의자가 반드시 필요합니다.",
                    to: "/product#chair",
                    background : "/images/main/v9.jpg",
                },
                { 
                    title : "SOFA",
                    info : "세련되고 고급스러운 디자인과 소재로 마감해 어떤 비즈니스 공간에서도 안락하고 격조 있는 분위기를 연출합니다. <br class='d-none d-lg-block' />미니멀리즘을 강조한 모던 스타일의 비츠 소파는 단순한 가구가 아니라 세련된 공간을 만드는 하나의 오브제입니다. <br class='d-none d-lg-block' />남다른 감각과 센스가 돋보이는 비츠 소파 시리즈로 개성 있고 아늑한 공간을 연출해보십시오.",
                    to : "/product#sofa",
                    background : "/images/main/v10.jpg",
                },
            ],
        };
    },
    mounted() {
        this.init();
    },
	methods : {
		init : function() {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 0,
                    duration: 500,
                    throttleDelay : 100,
                    easing : 'ease-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
	},
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        display: block;
        height: 100vh;
        display: flex;
        align-items: center;
    }
    &__bg{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__contents{
        width: 100%;
        position: relative;
    }
    &__btn{
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 80px;
        background-color: rgba($color: #000, $alpha: .3);
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color:  rgba($color: #fff, $alpha: .7);
        transition: 0.1s ease-out;
        &--prev{
            left: 0;
            border-radius: 0 999px 999px 0;
        }
        &--next{
            right: 0;
            border-radius: 999px 0 0 999px;
        }
    }
    &__title{
        max-width: 240px;
        background-size: contain;
    }
    .swiper-control{
        bottom: 40px;
        max-width: calc(var(--container) + var(--container-gutter) * 2);
        .swiper-pagination.swiper-pagination-bullets{
            position: relative;
            left: 0;
            bottom: 0;
            align-items: center;
            margin-right: -12px;
        }
    }
}
::v-deep{
    .swiper-pagination-bullet{
        width: 16px;
        height: auto;
        background-color: transparent !important;
        font-size: 1.2rem;
        font-weight: 700;
        text-align: left;
        color: rgba($color: #fff, $alpha: .4);
        border: 0;
        margin: 0 12px 0 0;
        padding-right: 0;
        transition: 0.25s ease-out;
        opacity: 1;
        &::after{
            width: 24px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            border-radius: 0;
            top: 50%;
            right: 0;
            transform: translateY(-50%) scaleX(0);
            transform-origin: right center;
            transition: 0.25s ease-out;
        }
        &-active{
            width: 64px;
            color: #fff;
            &::after{
                transform: translateY(-50%) scaleX(1);
            }
        }
    }
}
.line{
    display: block;
    width: 1px;
    height: 16px;
    background-color: #fff;
}
@media (min-width: 576px) {
    .main-visual {
        &__title{
            max-width: 320px;
        }
    }
}
@media (min-width: 768px) {
    .main-visual {
        &__title{
            max-width: 500px;
        }
        .swiper-control{
            bottom: 80px;
            .swiper-pagination.swiper-pagination-bullets{
                justify-content: flex-start;
                margin-right: -24px;
            }
        }
    }
    ::v-deep{
        .swiper-pagination-bullet{
            width: 20px;
            font-size: 1.4rem;
            margin: 0 24px 0 0;
            &::after{
                width: 40px;
            }
            &-active{
                width: 84px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__btn:hover{
            background-color: var(--v-primary-base);
            color: #fff;
        }
        &__title{
            max-width: 700px;
        }
    }
}
@media (min-width: 1300px) {
    .main-visual {        
        &__btn{
            display: flex;
        }
    }
}
@media (min-width: 1740px) {
    .main-visual {        
        .container,
        .swiper-control {
            max-width: calc(var(--container-lg) + var(--container-gutter)*2);
        }
    }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pr-md-30px pb-40px pb-md-0"
  }, [_c('tit-wrap-primary', {
    staticClass: "pb-0 white--text",
    scopedSlots: _vm._u([{
      key: "TxtAdd",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text"
        }, [_vm._v(" 고객이 꿈꾸는 최적의 오피스 공간을 제안하고, 비츠만의 토탈 디자인 솔루션을 통해 "), _c('br', {
          staticClass: "d-none d-lg-block"
        }), _vm._v(" 오피스 환경 개선 및 고객 맞춤형 공간을 구현해드립니다. ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" Contact Us ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "inquiry-cards",
    attrs: {
      "color": "transparent",
      "max-width": "100%"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.inquiry, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "6"
      }
    }, [_c('v-responsive', {
      staticClass: "h-100",
      attrs: {
        "width": "100%",
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-card', {
      staticClass: "inquiry-card h-100",
      attrs: {
        "data-aos": item.aos,
        "data-aos-delay": item.aosDelay,
        "to": item.link,
        "tile": "",
        "outlined": ""
      }
    }, [_c('div', {
      staticClass: "pa-20px pa-md-30px pa-lg-40px h-100 d-flex flex-column justify-space-between"
    }, [_c('div', {
      staticClass: "mb-30px"
    }, [_c('tit', {
      staticClass: "tit--sm break-keep white--text mb-6px mb-md-12px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('txt', {
      staticClass: "txt--xs white--text line-height-15"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.info)
      }
    })])], 1), _c('btn-arrow-primary', {
      staticClass: "v-btn--none-active-bg",
      attrs: {
        "color": "white",
        "direction": "right"
      }
    })], 1)])], 1)], 1);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }